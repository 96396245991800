// Cores
$branco: #fff; /* Cor padrão branca*/
$preto: #000; /* Cor Padrão Preta - Tom de Preto com base no Layout */


//$corMain: #ffcc33; /* Cor primária do site */
$corMain: #000; /* Cor primária do site */
$corSeta: #000; /* Cor da seta dos Sliders */
$bgBotaoConfiraOferta: #ffcc33; /* BG que envolve as setas */
$corTextoBotaoConferirOferta: #fff; /* Cor do Texto do botão de oferta e oferta menores */
$corTextoHeader: #fff;

$gray: #eeeeee;
$secondary-color: $gray;
// Fonts
$fontmain: 'nouvel-renault'; /* Fonte padrão de todo o Site */
$color-black: #3E3F40;
$blue: #04a3e1;

$path-fonts: "../../assets/fonts/";
$path-images: "../../assets/images/";

//cards color
$card-title: $color-black;
$card-price: $color-black;
$card-button: $color-black;

// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}

@font-face {
  font-family: nouvel-renault;
  src: url(../fonts/NouvelR-Regular.ttf);
}
