body{
	font:{
		size: 12px;
		weight: 400;
		family: $fontmain;
	};
	color: $preto;
}

h1, h2, h3, h4, h5{margin: 0px; font-weight: inherit;}

ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
	}
}

a{
	text-decoration: none;
	color: inherit;
	&:hover{
		text-decoration: none;
		color: inherit;
	}
	&:focus{
		outline: none;
		box-shadow: none;
	}
	&:active{
		text-decoration: none;
		color: inherit;
	}
}

// Gerais
