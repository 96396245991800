.internal-style {
  .internal-title {
    color: $preto;
    text-transform: uppercase;

    font-family: "OpenSansExtraBoldItalic";

    h2 {
      font-size: 44px;
      margin: 0;
      line-height: 40px;
    }

    h3 {
      font-size: 24px;
      margin: 0;
      line-height: 20px;
    }

    @media screen and (max-width: 425px) {
      h2 {
        font-size: 35px;
        line-height: 35px;
      }

      h3 {
      }
    }
  }

  .internal-banner {
    background: red;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 185px;
    }
  }

  .component-title {
    color: $preto;
  }
}

.box-interna-carro {
  margin-top: 50px;

  .marca-do-carro,
  .nome-do-carro {
    display: inline-block;
    &:hover {
      color: white;
    }
  }

  .marca-do-carro {
    vertical-align: top;
    padding-top: 5px;
    margin-right: 10px;
    img {
      display: inline-block;
    }
    &:hover {
      color: white;
    }
  }

  .nome-do-carro-interna {
    color: $corMain;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    display: inline-block;
    strong {
      font-size: 1.3rem;
      font-weight: 900;
    }
  }

  .fotos-do-carro {
    margin-top: 20px;
    position: relative;
    img {
      width: 100%;
      max-height: 580px;
    }
    .seta-esquerda,
    .seta-direita,
    .seta-direita-mobile,
    .seta-esquerda-mobile {
      z-index: 100;
      color: #fff;
      position: absolute;
      top: 45%;
      display: none;
      width: 50px;
      text-align: center;
      line-height: 50px;
      height: 50px;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      @include transition(0.3s);
      cursor: pointer;
      font-size: 50px;
    }
    &:hover {
      .seta-esquerda,
      .seta-direita,
      .seta-direita-mobile,
      .seta-esquerda-mobile {
        display: block;
      }
    }
    .seta-direita,
    .seta-direita-mobile {
      right: 0;
    }
    .seta-esquerda,
    .seta-esquerda-mobile {
      left: 0;
    }
  }

  .galeria-thumb {
    background: #e6e6e6;
    padding-top: 10px;
    text-align: center;
    .item-thumbs {
      max-width: 100px;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      img {
        display: inline-block;
        cursor: pointer;
        width: 100%;
        max-width: 95px;
      }
    }
  }

  .gallery-medias {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;

    .main-element {
      grid-row: span 2;
    }

    img,
    iframe {
      width: 100%;
    }

    .overlay-last-image {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      color: #f1f1f1;

      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.quadro-informacoes-do-carro {
  .item-informacao {
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 300;
    span {
      color: $preto;
    }
    img {
      display: inline-block;
      margin-right: 10px;
    }

    &.cinza {
      background: #f7f7f7;
    }
  }
}

.caixa-caracteristica {
  margin-bottom: 50px;

  .caixa-titulo {
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    height: 60px;
    color: $branco;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 25px;
    background: #313131;
    line-height: 60px;
  }

  .tag-principal {
    display: inline-block;
    width: 100%;
    color: $corMain;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 900;
  }

  .tag {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    color: $preto;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .info-caracteristica {
    display: inline-block;
    width: 100%;
    color: $preto;
    font-weight: 400;
    padding-left: 20px;
    font-size: 12px;
    margin-bottom: 30px;
    padding-right: 60px;
  }
}

.compartilhar {
  border-top: solid 1px #aaaaaa;
  padding-top: 10px;
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;

  span {
    font-size: 12px;
    color: $preto;
  }
}

.compartilhar-btn {
  text-align: center;
  width: 100%;
  @include radius(5px);
  text-transform: uppercase;
  color: $branco;
  font-weight: 300;
  font-size: 14px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-top: 20px;
  &:hover {
    color: $branco;
  }

  &.whatsapp {
    background: #65bc54;
  }
  &.facebook {
    background: #3c6ecb;
  }
  &.youtube {
    background: #dc3333;
  }
  &.twitter {
    background: #52d4f9;
  }
  &.instagram {
    background: #e87e21;
  }
}

.lista-de-caracteristicas {
  display: flex;
  li {
    display: flex;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    span {
      font-size: 12px;
      color: $corMain;
    }
  }
  li:first-child {
    margin-left: 0;
  }
}

.checks {
}

.opcional-check {
  width: 100%;
  display: inline-block;
  color: $corMain;
  border: solid 1px #e1e1e1;
  padding: 10px;
  i {
    margin-right: 5px;
    font-size: 18px;
    color: #27ae60;
  }
}

.valor-carro {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  .cifrao {
    color: $corMain;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
  }

  .valor {
    color: $corMain;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
  }
}
.de {
  color: $corMain;
  font-size: 14px;

  .valor-antes {
    text-decoration: line-through;
  }
}

.car-right-box {
  position: absolute;
}

.lead-preco {
  border-top: solid 1px #fff;
  background: $preto;
  text-align: center;
}

.tag-lead-interna {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  color: $branco;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  padding: 0px 10px;
}

.enviar-lead-interna {
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
  background: $branco;
  color: $preto;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font: {
    size: 16px;
  }
  @include transition(0.3s);
  @include radius(1px);

  i {
    font-size: 12px;
  }

  &:hover {
    background-color: darken($corMain, 10%);
    color: #fff;
    border: solid 1px #777;
  }
}

.tag-lead-menor {
  color: $branco;
  font-size: 12px;
  font-weight: 800;
  display: inline-block;
}

.box-radios {
  .radio {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    label {
      color: #615f6c;
      font-size: 11px;
      font-weight: 300;
      color: $branco;
    }
  }
}

.btn-simulacao {
  background-color: $corMain;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: $branco;
  font-weight: 800;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  width: 95%;

  &:hover {
    color: $branco;
    background-color: darken($corMain, 10%);
  }
}

.lead-interna-carro {
  input {
    border: none;
    @include radius(0);
    margin-bottom: 12px;
    color: #1b1b1b;
  }

  textarea {
    border: none;
    @include radius(0);
    margin-bottom: 12px;
    height: 100px;
    color: #1b1b1b;
  }
}

.box-inputs {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 992px) {
  .car-right-box {
    position: absolute;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 320px) {
  .box-interna-carro {
    margin-top: 0px;
  }
  .box-interna-carro .fotos-do-carro {
    margin-top: 0;
  }

  .car-right-box {
    position: relative;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .box-interna-carro .fotos-do-carro {
    margin-top: 20px;
  }
  .box-interna-carro {
    margin-top: 20px;
  }
}
