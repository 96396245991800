@media screen and (min-width: 320px) {
	/* 320px */

	// Slider Home

	.seta-direita,
	.seta-esquerda,
	.seta-slider-de-carros-direita,
	.seta-slider-de-carros-esquerda,
	.seta-slider-venda-direta-esquerda,
	.seta-slider-venda-direta-direita,
	.seta-slider-simule-direita,
	.seta-slider-simule-esquerda,
	.seta-peca-direita,
	.seta-peca-esquerda,
	.seta-outro-esquerda,
	.seta-outro-direita{ display: none !important; }

	// Filtro Categoria

	.filtro{
		li{
			border: none;
		}
	}

	// Botoes

	.botao-pos-venda{
		margin-bottom: 30px;
		i{ display: none; }
		img{ margin-right: 5px; }
	}
	.botao-ver-mais-carros{ i{ display: none; } }

	.menu-footer{ margin-bottom: 30px; }

	.atendimento{ display: block; }
	.ass{ text-align: center; margin-top: 30px; }

	.horarios-e-mapa{
		text-align: center;
		border-left: none;
		.mapa{ margin-bottom: 20px; }
	}

	.logo-da-empresa,
	.horario,
	.nome-da-loja{
		margin-right: 0;
		width: 100%;
		text-align: center;
	}



	footer{ text-align: center; }


	.clique-menu{
		display: inline-block;
		color: $branco;
		font-size: 24px;
		font-weight: 700;
		margin-top: 10px;
		i{ margin-left: 5px; }
	}


	.box-menu-mobile{
		background: #000;
		position: absolute;
		width: 100%;
		text-align: center;
		z-index: 9000;
		left: 0; top: 0;
		border-top: 8px solid #fff;
		display: none;
	}

	.clique-fechar{
		text-transform: uppercase;
		font-weight: 900;
		text-align: right;
		width: 100%;
		display: inline-block;
		font-size: 15px;
		color: $branco;
		margin-top: 30px;
		margin-bottom: 30px;
		padding-right: 20px;
	}

	.menu-mobile{
		li{
			text-align: center;
			display: block;
			width: 100%;
			position: relative;
			margin-bottom: 30px;
			a{
				text-transform: uppercase;
				font-weight: 900;
				font-size: 26px;
				color: $branco;
				&:hover{
					text-decoration: underline;
				}
			}

			.sub-menu{
				background: #252525;
				width: 100%;
				z-index: 9000;
				display: none;
				li{
					width: 100%;
					padding-top: 20px;
					text-align: center;
					margin-bottom: 0;
					@include transition(0.3s);
					a{
						display: block;
						width: 100%;
						color: $branco;
						text-align: center;
						padding-bottom: 20px;
						border-bottom: solid 1px $branco;
					}

					&:hover{
						background: $corMain;
						a{ border-color: $corMain; text-decoration: none;}
					}

					&:last-child{
						a{
							border-bottom: none;
						}
					}
				}
			}
		}
	}

	.box-contato{
		.sobre-pagina{
			padding-left: 30px;
			padding-right: 15px;
			margin-top: -200px;
		}
	}

	.sobre-pagina{
		padding-left: 15px;
		padding-right: 15px;
		margin-top: -200px;
	}

	.menor-ativo{ margin-left: 0; }

}


@media screen and (min-width: 540px) {

}

@media screen and (min-width: 768px) {
	.slider-principal {
		.item-slider{
			height: auto;
			padding-top: 0;
			img {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 992px) {

	.slider-principal {
		&.--mobile {
			display: none;
		}
		&.--desktop {
			display: block;
		}
		.item-slider{
			height: 470px;
			padding-top: 0;
			img {
				width: 100%;
			}
		}
	}

	// Filtro Categoria

	.filtro{
		li{
			border-right: solid 1px #7e7e7e;
		}
	}

	// Botões

	.botao-pos-venda{
		margin-bottom: 30px;
		i{ display: block; }
		img{ margin-right: 25px; }
	}

	.botao-ver-mais-carros{ i{ display: inline-block; } }

	.menu-footer{ margin-bottom: 0px; }

	footer{  text-align: left; }


	.ass{ text-align: right; margin-top: 0px; }

	.horarios-e-mapa{
		text-align: right;
		border-left: solid 1px #e3e3e3;
		.mapa{ margin-bottom: 0px; text-align: left;}
	}

	.logo-da-empresa{
		margin-right: 30px;
		width: auto;
		text-align: left;
	}

	.horario,
	.nome-da-loja{
		width: auto;
		text-align: left;
	}


	.seta-direita,
	.seta-esquerda,
	.seta-slider-de-carros-direita,
	.seta-slider-de-carros-esquerda,
	.seta-slider-venda-direta-esquerda,
	.seta-slider-venda-direta-direita,
	.seta-slider-simule-direita,
	.seta-slider-simule-esquerda,
	.seta-peca-direita,
	.seta-peca-esquerda{ display: none !important; }

	.menor-ativo{ margin-left: 30px; }

}

@media screen and (min-width: 1200px) {
	// Slider Home

	.seta-direita,
	.seta-esquerda,
	.seta-slider-de-carros-direita,
	.seta-slider-de-carros-esquerda,
	.seta-slider-venda-direta-esquerda,
	.seta-slider-venda-direta-direita,
	.seta-slider-simule-direita,
	.seta-slider-simule-esquerda,
	.seta-peca-direita,
	.seta-peca-esquerda,
	.seta-outro-esquerda,
	.seta-outro-direita{ display: inline-block !important; }

	.seta-slider-de-carros-direita{
		right: 1%;
	}
	.seta-slider-de-carros-esquerda{
		left: 1%;
	}

	.box-contato{
		.sobre-pagina{
			padding-left: 95px;
			padding-right: 95px;
			margin-top: -80px;
		}
	}

	.sobre-pagina{
		padding-left: 95px;
		padding-right: 95px;
		margin-top: -80px;
	}
}

@media screen and (min-width: 1400px) {
	.seta-slider-de-carros-direita{
		right: -5%;
	}
	.seta-slider-de-carros-esquerda{
		left: -5%;
	}
}

@media screen and (max-width: 540px) {
  .horario,
  .mapa{
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
	.botao-pos-venda{
		img{display:none; }
}
}
