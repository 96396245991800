@charset "UTF-8";
/*===========================================
	VARIAVEIS
============================================*/
/* Cor padrão branca*/
/* Cor Padrão Preta - Tom de Preto com base no Layout */
/* Cor primária do site */
/* Cor da seta dos Sliders */
/* BG que envolve as setas */
/* Cor do Texto do botão de oferta e oferta menores */
/* Fonte padrão de todo o Site */
@font-face {
  font-family: nouvel-renault;
  src: url(../fonts/NouvelR-Regular.ttf);
}
/*===========================================
	BASE
============================================*/
body {
  font-size: 12px;
  font-weight: 400;
  font-family: "nouvel-renault";
  color: #000;
}

h1, h2, h3, h4, h5 {
  margin: 0px;
  font-weight: inherit;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:focus {
  outline: none;
  box-shadow: none;
}
a:active {
  text-decoration: none;
  color: inherit;
}

/*===========================================
	HEADER
============================================*/
header {
  background: #000;
  box-shadow: 0px 1px 3.92px 0.08px rgba(0, 0, 0, 0.3);
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  font-size: inherit;
}

.atendimento {
  display: inline-block;
}
.atendimento li {
  display: inline-block;
  padding-right: 25px;
  padding-left: 25px;
  border-right: solid 1px #000;
}
.atendimento li .icone-atendiento {
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: top;
  color: #fff;
}
.atendimento li .numero-atendimento {
  display: inline-block;
}
.atendimento li .numero-atendimento span {
  display: block;
  font-size: 14px;
  color: #fff;
}
.atendimento li .numero-atendimento strong {
  display: block;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}
.atendimento li:last-child {
  padding-right: 0;
  border-right: none;
}

.menu-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sub-header {
  padding: 20px 0px;
}

.nome-duas-cores {
  margin-bottom: 0;
}

.uma-cor, .duas-cor {
  color: #000;
  font-size: 32px;
  font-style: italic;
}

.uma-cor {
  font-weight: 700;
}

.duas-cor {
  font-weight: normal;
}

.menu li {
  display: inline-block;
  padding-right: 25px;
  position: relative;
  padding: 10px;
}
.menu li a {
  text-transform: uppercase;
  font-weight: 300;
}
.menu li a:hover {
  text-decoration: underline;
}
.menu li .sub-menu {
  position: absolute;
  top: 32px;
  left: 0;
  background: #252525;
  z-index: 200;
  display: none;
}
.menu li .sub-menu li {
  width: 250px;
  margin-right: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 30px;
  padding-bottom: 0;
  padding-right: 0;
}
.menu li .sub-menu li a {
  display: block;
  color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: solid 1px #fff;
}
.menu li .sub-menu li:hover {
  background: #000;
}
.menu li .sub-menu li:hover a {
  border-color: #000;
  text-decoration: none;
}
.menu li .sub-menu li:last-child a {
  border-bottom: none;
}
.menu li:hover .sub-menu {
  display: block;
}

.icones-leads {
  text-align: right;
  margin-top: 35px;
}
.icones-leads span {
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
}
.icones-leads span i {
  font-size: 25px;
}
.icones-leads span .fa-mobile {
  font-size: 30px;
  vertical-align: top;
}

/*===========================================
	CONTENT
============================================*/
.mega-box-slider {
  position: relative;
  width: 100%;
  height: auto;
}

.slider-principal {
  visibility: hidden;
}
.slider-principal.--mobile {
  display: block;
}
.slider-principal.--desktop {
  display: none;
}
.slider-principal .container {
  padding: 0px;
}
.slider-principal .slick-slide:focus {
  outline: none;
}
.slider-principal .slick-slide img {
  max-width: 100%;
}
.slider-principal .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}
.slider-principal .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-principal .slick-dots li.slick-active {
  background: #000;
}

.seta-direita,
.seta-outro-direita,
.seta-outro-esquerda,
.seta-esquerda,
.seta-slider-venda-direta-esquerda,
.seta-slider-venda-direta-direita,
.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-simule-esquerda,
.seta-slider-simule-direita,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-semi-direita,
.seta-adicionais-direita,
.seta-adicionais-esquerda {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #000;
  color: #322f26;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 40%;
}
.seta-direita i,
.seta-outro-direita i,
.seta-outro-esquerda i,
.seta-esquerda i,
.seta-slider-venda-direta-esquerda i,
.seta-slider-venda-direta-direita i,
.seta-slider-de-carros-esquerda i,
.seta-slider-de-carros-direita i,
.seta-slider-simule-esquerda i,
.seta-slider-simule-direita i,
.seta-peca-direita i,
.seta-peca-esquerda i,
.seta-semi-esquerda i,
.seta-semi-direita i,
.seta-adicionais-direita i,
.seta-adicionais-esquerda i {
  font-size: 40px;
  color: #000;
}

.seta-direita {
  right: 0;
  z-index: 1000;
}

.seta-esquerda {
  left: 0;
  z-index: 1000;
}

.seta-semi-esquerda,
.seta-semi-direita {
  top: 55%;
}

.seta-slider-venda-direta-esquerda {
  left: -50px;
  top: 53%;
}

.seta-slider-venda-direta-direita {
  right: -50px;
  top: 53%;
}

.seta-slider-de-carros-esquerda {
  left: 0px;
}

.seta-slider-de-carros-direita {
  right: 0px;
}

.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita {
  top: 53%;
}

.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-slider-simule-esquerda {
  left: 15%;
}

.seta-peca-direita,
.seta-semi-direita,
.seta-slider-simule-direita {
  right: 15%;
}

.seta-slider-simule-esquerda,
.seta-slider-simule-direita {
  top: 40%;
}

.item-slider {
  background-position: center center;
  background-size: cover;
}

.box-interessado span {
  display: block;
  font-weight: 900;
  font-size: 48px;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.box-interessado span.pequeno-texto-slider {
  font-size: 16px;
  margin-bottom: -10px;
}
.box-interessado span.preco-do-carro-slider {
  font-weight: 300;
  margin-top: -20px;
  margin-bottom: 20px;
}

.estou-interessado {
  display: inline-block;
  background-color: #62ddbe;
  box-shadow: inset 0px 3px 0px 0px rgba(179, 255, 255, 0.75);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 900;
  color: #fff;
}
.estou-interessado i {
  color: #fff;
  margin-left: 10px;
}
.estou-interessado:hover {
  color: #fff;
}

.box {
  margin-top: 50px;
}

.box-escolha-um-carro {
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
}

.titulo-veiculos {
  background: #000 !important;
  padding-bottom: 60px !important;
}
.titulo-veiculos h1 {
  color: #fff !important;
  font-size: 3.5rem !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}
.titulo-veiculos h3 {
  color: #fff !important;
  text-transform: none !important;
  font-weight: unset !important;
}

.box-titulo-e-sub-titulo {
  margin-bottom: 30px;
}
.box-titulo-e-sub-titulo h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  color: #000;
}
.box-titulo-e-sub-titulo h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
}

.box-filtro span {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 20px;
  display: block;
}

.filtro {
  display: inline-block;
  border: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 70px;
}
.filtro li {
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  border-right: solid 1px #7e7e7e;
}
.filtro li a {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  color: #636363;
}
.filtro li:last-child {
  border-right: none;
}
.filtro li.filtro-ativo a {
  font-weight: 400;
}

.slider-de-carros,
.slider-simule,
.slider-peca,
.slider-seminovos {
  height: 300px;
}
.slider-de-carros .slick-dots,
.slider-simule .slick-dots,
.slider-peca .slick-dots,
.slider-seminovos .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}
.slider-de-carros .slick-dots li,
.slider-simule .slick-dots li,
.slider-peca .slick-dots li,
.slider-seminovos .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.slider-de-carros .slick-dots li.slick-active,
.slider-simule .slick-dots li.slick-active,
.slider-peca .slick-dots li.slick-active,
.slider-seminovos .slick-dots li.slick-active {
  background: #000;
}

.item-carros,
.item-interno-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #eeeeee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
}
.item-carros img,
.item-interno-carros img {
  width: 100%;
  max-height: 155px;
  text-align: center;
}

.item-interno-carros {
  margin-right: 0px;
  margin-left: 0;
}
.item-interno-carros .a-partir {
  margin-bottom: 5px;
}
.item-interno-carros .confira-oferta {
  margin-top: 10px;
}

.carros-novos {
  margin-bottom: 50px;
}

.slider-simule .item-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  border: solid 1px #eeeeee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  height: 180px;
  overflow: hidden;
}

.slider-seminovos .item-carros {
  background: #fff;
}

.cor-semi .box-titulo-e-sub-titulo h3,
.cor-semi .box-titulo-e-sub-titulo h1 {
  color: #fff;
}

.nome-do-carro {
  display: block;
  font-size: 18px;
  font-weight: 900;
  color: #636363;
  text-transform: uppercase;
}

.a-partir {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #636363;
  margin-bottom: 20px;
}

.confira-oferta {
  display: inline-block;
  color: #fff;
  background: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.confira-oferta:hover {
  background: #000;
  color: #fff;
}

.item-carros:hover {
  height: 300px;
}

.simule-cor {
  background: #fafafa;
}

.no-margin {
  margin: 0;
}

.escolha-outro {
  margin-top: 30px;
  display: inline-block;
  background: #000;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
  padding-right: 55px;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.escolha-outro i {
  margin-left: 20px;
}
.escolha-outro:hover {
  background: #bb3c2b;
  color: #fff;
}

.cor-semi {
  background: #000;
}

.procurar-semi-novo {
  margin-bottom: 65px;
}
.procurar-semi-novo input {
  background: none;
  border: solid 1px #fff;
  height: 80px;
  color: #fff;
  border-right: none;
}
.procurar-semi-novo input:focus {
  outline: none;
}
.procurar-semi-novo .input-group-addon {
  background: none;
  border-color: #fff;
}
.procurar-semi-novo .input-group-addon i {
  color: #fff;
  font-size: 20px;
}

.venda-direta .box-titulo-e-sub-titulo {
  margin-top: 20px;
}

.container-slider-venda-direta {
  position: relative;
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
}
.container-cards .card-itens {
  margin-bottom: 10px;
}
.container-cards .card-itens h4 {
  margin-top: 10px;
  text-transform: uppercase;
}
.container-cards .card-itens p {
  font-size: 1.3rem;
}

.box-atendimento-pos {
  padding-top: 85px;
  padding-bottom: 85px;
  background-size: cover;
  background-position: center center;
}
.box-atendimento-pos .box-titulo-e-sub-titulo h3,
.box-atendimento-pos .box-titulo-e-sub-titulo h1 {
  color: #fff;
}

.botao-pos-venda {
  display: inline-block;
  border-bottom: solid 5px transparent;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.botao-pos-venda img {
  vertical-align: top;
  margin-right: 25px;
}
.botao-pos-venda .texto-do-botao {
  display: inline-block;
  margin-top: 10px;
}
.botao-pos-venda .texto-do-botao span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: -8px;
}
.botao-pos-venda .texto-do-botao span strong {
  font-weight: 900;
}
.botao-pos-venda i {
  position: absolute;
  right: 25px;
  color: #fff;
  top: 30%;
  font-size: 30px;
}
.botao-pos-venda.pos-whats {
  background: #35a648;
  border-color: #40c957;
}
.botao-pos-venda.pos-whats:hover {
  background: #40c957;
  border-color: #35a648;
}
.botao-pos-venda.pos-pecas {
  background: #228bab;
  border-color: #36a9cc;
}
.botao-pos-venda.pos-pecas:hover {
  background: #36a9cc;
  border-color: #228bab;
}
.botao-pos-venda.pos-revisao {
  background: #b45545;
  border-color: #ff7c66;
}
.botao-pos-venda.pos-revisao:hover {
  background: #ff7c66;
  border-color: #b45545;
}

.renault-atendimento .box-titulo-e-sub-titulo h3,
.renault-atendimento .box-titulo-e-sub-titulo h1 {
  color: #000;
}
.renault-atendimento .botao-pos-venda {
  background: #252525;
  border-color: #636363;
  padding-top: 10px;
  padding-bottom: 10px;
}
.renault-atendimento .botao-pos-venda:hover {
  background: #636363;
  border-color: #252525;
}

.pequeno-texto {
  text-align: center;
  margin-bottom: 50px;
}
.pequeno-texto p {
  font-size: 18px;
  font-weight: 300;
}

.box-mini-mapa {
  background: #1b1b1b;
  padding-top: 15px;
  padding-bottom: 15px;
}
.box-mini-mapa span {
  color: #fff;
  text-transform: uppercase;
}
.box-mini-mapa span a {
  font-weight: 700;
}

.nome-da-categoria {
  padding-bottom: 15px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.nome-da-categoria span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  color: #636363;
}

.sobre-pagina {
  border: solid 1px #e5e5e5;
  display: inline-block;
  padding-left: 95px;
  padding-right: 95px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  margin-top: -80px;
  background: #fff;
  width: 75%;
}
.sobre-pagina .box-titulo-e-sub-titulo {
  margin-bottom: 50px;
}
.sobre-pagina .pequeno-texto {
  margin-bottom: 30px;
  text-align: left;
}
.sobre-pagina .botao-pos-venda,
.sobre-pagina .pos-whats {
  padding-top: 10px;
  margin-top: 50px;
}

.box-imagem-interna {
  height: 380px;
}
.box-imagem-interna img {
  width: 100%;
  max-width: 1180px;
}

.box-fotos-empresa {
  background: #fafafa;
  padding-top: 55px;
  padding-bottom: 55px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  margin-top: 50px;
}

.item-galeria {
  width: 100%;
}

.item-pager-galeria {
  display: inline-block;
  margin-right: -1px;
}

.pager-galeria {
  margin-bottom: 50px;
}

.ligamos-pra-voce {
  text-align: center;
  padding-bottom: 22px;
  border-color: #252525;
  background: #636363;
  padding-top: 10px;
}
.ligamos-pra-voce img {
  position: absolute;
  left: 30px;
}
.ligamos-pra-voce .texto-do-botao {
  margin-top: 15px;
}
.ligamos-pra-voce .texto-do-botao span {
  display: inline-block;
}
.ligamos-pra-voce:hover {
  background: #252525;
  border-color: #636363;
}

.icone-telefone {
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
  margin-top: 10px;
}
.icone-telefone i {
  font-size: 20px;
}

.caixa-numero {
  display: inline-block;
  margin-left: 15px;
}
.caixa-numero span {
  display: block;
  font-size: 14px;
}

.contato {
  margin-bottom: 50px;
}
.contato textarea,
.contato input {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.botao-e-informacao {
  margin-bottom: 20px;
}

.botao-mais-infos {
  width: 100%;
  position: relative;
  background: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}
.botao-mais-infos span {
  font-size: 16px;
  font-weight: 300;
}
.botao-mais-infos .fa-plus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 1;
}
.botao-mais-infos .fa-minus {
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 18px;
  opacity: 0;
}

.box-mais-info {
  background: #eeeeee;
  padding-top: 35px;
  padding-bottom: 35px;
}

.texto-mais-info {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.botoes-menores {
  display: inline-block;
  color: #fff;
  background: #ffcc33;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.botoes-menores:hover {
  color: #fff;
  background: #000;
}

.menor-ativo {
  background: #000;
  margin-left: 30px;
}

.mais-ativo {
  background: #636363;
  color: #fff;
}
.mais-ativo .fa-plus {
  opacity: 0;
}
.mais-ativo .fa-minus {
  opacity: 1;
}

.box-escolha-carro {
  position: relative;
  /*margin-top: 100px;*/
  margin-bottom: 100px;
}
.box-escolha-carro .seta-slider-simule-esquerda,
.box-escolha-carro .seta-slider-simule-direita {
  top: 20%;
}

.interna-consorcio {
  margin-top: 0;
  padding-top: 40px;
  background: #fafafa;
}

.card-escolha-versao {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
}

.nome-e-ano {
  font-size: 48px;
  display: block;
}
.nome-e-ano strong {
  font-weight: 900;
}

.versao-do-carro {
  font-size: 18px;
  display: block;
  margin-bottom: 25px;
}
.versao-do-carro strong {
  font-weight: 900;
}

.texto-legal {
  font-size: 12px;
  color: #636363;
}

.imagem-do-carro {
  text-align: center;
  margin-bottom: 30px;
}

.card-escolha-versao .imagem-do-carro img {
  width: 100%;
  display: inline-block;
  max-width: 280px;
}

.escolha-versao {
  height: 35px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 25px;
  font-size: 14px;
}

.box-de-preco-da-versao .parcelas-consorcio {
  display: block;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: #636363;
}
.box-de-preco-da-versao .valor-consorcio {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #636363;
  font-size: 30px;
  font-weight: 300;
}
.box-de-preco-da-versao .valor-consorcio em {
  font-style: normal;
  text-transform: none;
  font-size: 14px;
}
.box-de-preco-da-versao .credito-consorcio {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #636363;
  margin-top: 25px;
}
.box-de-preco-da-versao .valor-do-credito-consorcio {
  text-align: center;
  font-size: 18px;
  color: #636363;
  display: block;
}
.box-de-preco-da-versao .valor-do-credito-consorcio strong {
  font-weight: 900;
}

.card-plano-consorcio {
  padding-top: 35px;
  padding-bottom: 12px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-plano-consorcio .tempo-do-plano,
.card-plano-consorcio .contemplados {
  color: #252525;
  font-size: 14px;
  display: block;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano {
  color: #252525;
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}
.card-plano-consorcio .valor-do-plano strong {
  font-weight: 900;
}

.titulo-escolha-um-plano {
  display: inherit;
  margin-top: 60px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 50px;
}

.card-form-financiamento {
  padding: 50px;
  background: #fff;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}
.card-form-financiamento h1 {
  display: inherit;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 20px;
}

.custom-modal {
  display: inline-block;
  background: #000;
  padding-top: 45px;
  padding-left: 100px;
  padding-right: 100px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border-top-right-radius: 0;
  position: relative;
  padding-bottom: 50px;
}

#box-modal-floater {
  padding: 30px 0px 0px 0px;
  background: white;
}
#box-modal-floater .tag-preencher span {
  color: black;
}

.revisao-modal {
  max-width: 650px;
}

.tag-preencher {
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}
.tag-preencher span {
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
}
.tag-preencher i {
  color: #fff;
  font-size: 40px;
}

.modal-contato label {
  color: #fff;
}
.modal-contato input,
.modal-contato textarea,
.modal-contato select {
  color: #fff;
  border: none;
  border-bottom: solid 1px #c2c2c2;
  height: 40px;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
}
.modal-contato input:focus,
.modal-contato textarea:focus,
.modal-contato select:focus {
  border: solid 1px #fff;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.modal-contato input[disabled], .modal-contato input[readonly],
.modal-contato textarea[disabled],
.modal-contato textarea[readonly],
.modal-contato select[disabled],
.modal-contato select[readonly] {
  background-color: black;
}
.modal-contato select {
  padding: 6px;
}
.modal-contato select option {
  color: #1b1b1b;
}
.modal-contato textarea {
  min-height: 80px;
}
.modal-contato ::-webkit-input-placeholder {
  color: #fff;
}
.modal-contato :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
.modal-contato ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.modal-contato :-ms-input-placeholder {
  color: #fff;
}
.modal-contato span {
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-contato .enviar-modal {
  background: #313131;
  color: #fff;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
}
.modal-contato .enviar-modal i {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 30px;
  color: #fff;
}

.fechar-modal {
  width: 60px;
  right: 60px;
  position: absolute;
  right: 0;
  top: 0;
  background: #313131;
  text-align: center;
  line-height: 60px;
  z-index: 100;
}
.fechar-modal i {
  color: #fff;
  font-size: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.fechar-modal:hover i {
  color: #000;
}

.box-quadro-de-pecas {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.titulo-box-filtro-pecas {
  background: #333333;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: solid 1px #636363;
}
.titulo-box-filtro-pecas span {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
}

.pesquisa-filtro {
  background: #252525;
  border-bottom: solid 1px #636363;
  padding: 10px;
}
.pesquisa-filtro .input-group,
.pesquisa-filtro .form-group {
  width: 100%;
}
.pesquisa-filtro input {
  border: solid 1px #fff;
  height: 40px;
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  border-right: none;
}
.pesquisa-filtro input:focus {
  border: solid 1px #fff;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.pesquisa-filtro ::-webkit-input-placeholder {
  color: #fff;
}
.pesquisa-filtro :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
.pesquisa-filtro ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.pesquisa-filtro :-ms-input-placeholder {
  color: #fff;
}
.pesquisa-filtro .input-group-addon {
  background: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-left: none;
  border-color: #fff;
}
.pesquisa-filtro .input-group-addon i {
  color: #fff;
}

.clique-carro {
  display: block;
  background: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: solid 1px #636363;
  padding-left: 10px;
  padding-right: 10px;
}
.clique-carro span {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
}
.clique-carro:hover {
  background: #000;
}
.clique-carro i {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.clique-carro.feita-escolha {
  background: #000;
  border-color: #000;
}
.clique-carro.feita-escolha span {
  font-weight: 700;
}
.clique-carro.feita-escolha i {
  opacity: 1;
}
.clique-carro.feita-escolha .fa-times {
  position: absolute;
  right: 0;
  top: 25px;
}

.barra-ordenar {
  background: #e3e3e3;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
.barra-ordenar span {
  text-transform: uppercase;
  color: #0f0f0f;
}
.barra-ordenar .nome-da-barra {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #1b1b1b;
}

.ordenar {
  display: inline-block;
  margin-left: 10px;
}
.ordenar li {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border-right: solid 1px #0f0f0f;
}
.ordenar li a {
  text-transform: uppercase;
  color: #0f0f0f;
}
.ordenar li:last-child {
  border-right: none;
}

.caixa-de-resultados .item-carros {
  width: 265px;
  margin-left: 7px;
  margin-bottom: 30px;
  margin-right: 7px;
}

.botoes-internos-carro {
  background: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 75px;
}

.box-versoes-com-mais {
  padding-bottom: 65px;
}

.box-fotos-do-carro {
  background: #fafafa;
  border-top: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 65px;
}

.interior-exterior {
  margin-bottom: 30px;
  position: relative;
}

.interior,
.exterior {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  background: #636363;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  padding-left: 65px;
  padding-right: 65px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.interior:hover,
.exterior:hover {
  background: #000;
  color: #fff;
}
.interior.ativo-in-ex,
.exterior.ativo-in-ex {
  background: #000;
  color: #fff;
}

.ativo-in-ex:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 12px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffcc33;
}

.cards-adicionais {
  margin-bottom: 100px;
  position: relative;
}
.cards-adicionais .slick-slide:focus {
  outline: none;
}

.card-adicional {
  margin-right: 10px;
  margin-left: 10px;
  width: 290px;
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eeeeee;
  opacity: 0.5;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.card-adicional .confira-oferta {
  margin-bottom: 20px;
}
.card-adicional:hover {
  opacity: 1;
}
.card-adicional.adicional-ativo {
  opacity: 1;
}

.nome-do-carro-adicional,
.modelo-do-carro-adicional,
.preco-do-carro-adicional {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}
.nome-do-carro-adicional strong,
.modelo-do-carro-adicional strong,
.preco-do-carro-adicional strong {
  font-weight: 900;
}

.modelo-do-carro-adicional {
  font-size: 18px;
}

.preco-do-carro-adicional {
  font-size: 16px;
  margin-bottom: 20px;
}

.thumb-foto-adicional {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.thumb-foto-adicional img {
  display: inline-block !important;
  width: 100%;
  max-width: 185px;
}

.adicionais-do-carro {
  display: none;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #1b1b1b;
}
.adicionais-do-carro li {
  color: #fff;
}

.ver-itens-adicional {
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
  color: #929292;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-itens-adicional:hover {
  color: #252525;
}

.seta-adicionais-direita {
  right: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-direita:hover {
  background: #000;
  border-color: #000;
}
.seta-adicionais-direita:hover i {
  color: #fff;
}

.seta-adicionais-esquerda {
  left: -5%;
  border: solid 1px #252525;
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda i {
  color: #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.seta-adicionais-esquerda:hover {
  background: #000;
  border-color: #000;
}
.seta-adicionais-esquerda:hover i {
  color: #fff;
}

.seta-versao-esquerda,
.seta-ultimo-esquerda,
.seta-ultimo-direita,
.seta-versao-direita {
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  top: -50px;
  color: #828282;
  font-weight: 900;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  vertical-align: top;
  cursor: pointer;
}
.seta-versao-esquerda i,
.seta-ultimo-esquerda i,
.seta-ultimo-direita i,
.seta-versao-direita i {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: solid 1px #252525;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #252525;
  text-align: center;
  line-height: 40px;
  margin-right: 15px;
  font-size: 24px;
}
.seta-versao-esquerda:hover,
.seta-ultimo-esquerda:hover,
.seta-ultimo-direita:hover,
.seta-versao-direita:hover {
  color: #252525;
}
.seta-versao-esquerda:hover i,
.seta-ultimo-esquerda:hover i,
.seta-ultimo-direita:hover i,
.seta-versao-direita:hover i {
  background: #000;
  color: #fff;
  border-color: #000;
}

.seta-versao-esquerda {
  left: 0;
}

.seta-versao-direita {
  right: 0;
}
.seta-versao-direita i {
  margin-left: 10px;
  margin-right: 0;
}

.item-outro-slider {
  display: inline-block;
  width: 280px;
  background: #fff;
  height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}

.thumb-imagem-outro {
  margin-bottom: 25px;
}
.thumb-imagem-outro img {
  width: 100%;
}

.outro-titulo {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  color: #636363;
  margin-bottom: 30px;
  text-align: center;
}

.outro-texto {
  padding-left: 15px;
  padding-right: 15px;
}
.outro-texto p {
  font-weight: 300;
  font-size: 12px;
  color: #636363;
}

.outro-slider .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 50px;
}
.outro-slider .slick-dots li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: -9999px;
  background: #7f7f7f;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.outro-slider .slick-dots li.slick-active {
  background: #000;
}

.seta-outro-direita {
  right: 0px;
}

.seta-outro-esquerda {
  left: 0px;
}

.box-escolha-um-carro .item-versao .sobre-pagina {
  margin-top: 0;
}
.box-escolha-um-carro .item-versao .sobre-pagina .card-adicional {
  opacity: 1;
  border: none;
}

.box-escolha-um-carro .setas-versoes .seta-ultimo-direita,
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  top: 40%;
  z-index: 100;
  background: #fff;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-direita {
  right: -25px;
}
.box-escolha-um-carro .setas-versoes .seta-ultimo-esquerda {
  left: -25px;
}

.item-pager-galeria {
  cursor: pointer;
}

.galeria-fotos {
  width: 100%;
}
@media screen and (max-width: 425px) {
  .galeria-fotos {
    height: 135px;
  }
}
.galeria-fotos picture {
  float: left;
  min-height: 600px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16.666%;
  height: 33.333%;
}
.galeria-fotos picture:first-child {
  width: 50%;
  height: 100%;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.box-carros-novos .nav-tabs {
  justify-content: center;
  margin-bottom: 40px;
  border-bottom: none !important;
}
.box-carros-novos .nav-tabs .nav-item button {
  border: none;
  font-size: 1.15rem;
  font-weight: bold;
  color: #a9a6a9;
}
.box-carros-novos .nav-tabs .nav-item button.active {
  color: #000;
  border-bottom: solid 3px #000;
}

.item-slider {
  background: #000;
}

/*===========================================
	FOOTER
============================================*/
.horario-de-funcionamento {
  padding-top: 45px;
  padding-bottom: 40px;
}
.horario-de-funcionamento .nome-da-loja {
  display: inline-block;
  margin-top: 20px;
}

.logo-da-empresa {
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
  margin-top: 20px;
}

.horarios-e-mapa {
  width: 100%;
  text-align: right;
  padding-top: 35px;
  padding-bottom: 35px;
  border-left: solid 1px #e3e3e3;
}

.icone {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.icone i {
  color: #000;
  font-size: 24px;
  margin-top: 5px;
}

.ver-google-maps {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin-top: 10px;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ver-google-maps i {
  vertical-align: top;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: -1px;
}
.ver-google-maps:hover i {
  margin-left: 10px;
}

footer {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #000;
}
footer .atendimento li {
  padding: 0;
  display: block;
  margin-bottom: 30px;
  color: #e5e5e5;
}
footer .atendimento li:last-child {
  padding: 0;
}

.horario,
.mapa {
  text-align: left;
}

.horario {
  margin-top: 10px;
}

.text-horario {
  font-size: 14px;
}

.tag-coluna {
  color: #e5e5e5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 35px;
  display: inline-block;
}

.menu-footer li {
  margin-top: 7px;
  margin-bottom: 7px;
}
.menu-footer li a {
  color: #e5e5e5;
  font-size: 14px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.menu-footer li a:hover {
  color: #000;
  font-weight: 800;
}

.ms-footer li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ms-footer li i {
  color: #000;
  background: #636363;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ms-footer li i:hover {
  background: #e5e5e5;
}

.ass-lua {
  margin-top: 70px;
  border-top: solid 1px #636363;
  padding-top: 20px;
}

.ass-footer {
  display: block;
  color: #636363;
  font-size: 12px;
}

/*===========================================
	BOOTSTRAP
============================================*/
.alertify,
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */
}

.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045); /* easeInBack */
}

.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  filter: alpha(opacity=0);
  opacity: 0;
}

.alertify-cover-hidden {
  display: none;
}

.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  opacity: 1;
}

.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  opacity: 0;
  display: none;
}

/* overwrite display: none; for everything except IE6-8 */
:root * > .alertify-hidden {
  display: block;
  visibility: hidden;
}

.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px;
}

.alertify-logs-hidden {
  display: none;
}

.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0;
}

.alertify-log-show {
  right: 0;
  opacity: 1;
}

.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0;
}

.alertify-dialog {
  padding: 25px;
}

.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.alertify-inner {
  text-align: center;
}

.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

.alertify-button,
.alertify-button:hover,
.alertify-button:active,
.alertify-button:visited {
  background: none;
  text-decoration: none;
  border: none;
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .alertify {
    left: 5%;
    margin: 0;
  }
}
/**
 * Default Look and Feel
 */
.alertify,
.alertify-log {
  font-family: sans-serif;
}

.alertify {
  background: #FFF;
  border: 10px solid #333; /* browsers that don't support rgba */
  border: 10px solid rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
  -moz-background-clip: padding; /* Firefox 3.6 */
  background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
}

.alertify-text {
  border: 1px solid #CCC;
  padding: 10px;
  border-radius: 4px;
}

.alertify-button {
  /*
  border-radius: 4px;
  color: #FFF;
  font-weight: bold;
  padding: 6px 15px;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  box-shadow: inset 0 1px 0 0 rgba(255,255,255,.5);
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:    -moz-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:     -ms-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:      -o-linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));
  background-image:         linear-gradient(top, rgba(255,255,255,.3), rgba(255,255,255,0));*/
  border: 0;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  color: #fff;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
}

.alertify-button:hover,
.alertify-button:focus {
  outline: none;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.alertify-button:focus {
  box-shadow: 0 0 15px #2B72D5;
}

.alertify-button:active {
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus {
  background-color: #FE1A00;
  border: 1px solid #D83526;
}

.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus {
  background-color: #f78a2a;
  border: 0;
}

.alertify-log {
  background: #1F1F1F;
  background: rgba(0, 0, 0, 0.9);
  padding: 15px;
  border-radius: 4px;
  color: #FFF;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-log-error {
  background: #FE1A00;
  background: rgba(254, 26, 0, 0.9);
}

.alertify-log-success {
  background: #5CB811;
  background: rgba(92, 184, 17, 0.9);
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 300px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*===========================================
	ESTOQUE
============================================*/
.consorcio-table td, .consorcio-table th {
  border: 1px solid #fff;
  padding: 5px;
}

.car-icon {
  display: inline-block;
  width: 20px;
  height: 21px;
  vertical-align: middle;
}
.car-icon.combustivel {
  background: url(../images/icones/icons_hover.png);
  background-position: -46px 0px;
  padding-left: 23px;
}
.car-icon.portas {
  background: transparent url("../images/icones/07.png") scroll no-repeat left center;
}
.car-icon.cor {
  background: transparent url("../images/icones/06.png") scroll no-repeat left center;
}
.car-icon.cambio {
  background: transparent url("../images/icones/05.png") scroll no-repeat left center;
}
.car-icon.ano {
  background: url(../images/icones/icons_hover.png);
  background-position: 0 0px;
  padding-left: 23px;
}
.car-icon.quilometragem {
  background: url(../images/icones/icons_hover.png);
  background-position: -24px 0px;
  padding-left: 23px;
}
.car-icon.motor {
  background: transparent url("../images/icones/02.png") scroll no-repeat left center;
}
.car-icon.car {
  background: transparent url("../images/icones/01.png") scroll no-repeat left center;
}

.card-carro {
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.card-carro .car-icon {
  color: #8D8D8D;
}
.card-carro .car-icon.ano {
  background: url(../images/icones/icons_hover.png);
  background-position: 0 0px;
}
.card-carro .car-icon.quilometragem {
  background: url(../images/icones/icons_hover.png);
  background-position: -24px 0px;
}
.card-carro .car-icon.combustivel {
  background: url(../images/icones/icons_hover.png);
  background-position: -46px 0px;
}
.card-carro:hover .valor {
  color: #000 !important;
}
.card-carro:hover .cifrao {
  color: #000 !important;
}
.card-carro:hover .nome-do-carro {
  color: #000 !important;
}
.card-carro:hover .versao {
  color: #000 !important;
}
.card-carro .tag-oferta {
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 140px;
  height: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 600;
  border-top: 20px solid #000;
  border-right: 15px solid transparent;
  border-left: 15px solid #000;
}
.card-carro .tag-oferta span {
  position: absolute;
  width: 115px;
  top: -22px;
  left: -10px;
}
.card-carro .botao-default {
  display: block;
  background: unset;
  text-transform: capitalize;
  color: #000;
  text-align: center;
  border-radius: 5px;
  border: solid 1px #000;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
  padding: 10px 10px !important;
  margin: 0;
}
.card-carro .botao-default i {
  font-size: 12px;
}
.card-carro .botao-default.ligamos-para-voce {
  background: #21a45c;
}
.card-carro .botao-default.ligamos-para-voce:hover {
  background: #26d775;
}
.card-carro .container-nome .marca-do-carro {
  display: inline-block;
  width: 100%;
}
.card-carro .container-nome .nome-do-carro {
  color: #000;
  width: 100%;
  display: flex;
  align-items: center;
}
.card-carro .container-nome .nome-do-carro images {
  width: 26px;
  margin-right: 10px;
}
.card-carro .container-nome .versao,
.card-carro .container-nome .tipo-do-carro {
  color: #000;
  height: 35px;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  font-size: 11px;
}
.card-carro .container-nome ul.caixa-specs {
  display: flex;
  justify-content: space-around;
}
.card-carro .container-nome ul.caixa-specs li {
  color: #999;
  font-size: 10px;
  padding: 5px 2px;
}
.card-carro .container-nome ul.caixa-specs li:first-child {
  border-left: none;
}
.card-carro .container-nome ul.caixa-specs li:last-child {
  border-right: none;
}
.card-carro .container-nome ul.caixa-specs li span.car-icon {
  width: auto;
  line-height: 20px;
}
.card-carro .caixa-whatsapp {
  text-align: center;
  background-color: #5cb85c;
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.caixa-foto {
  position: relative;
  margin-bottom: 15px;
  display: block;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.caixa-foto images {
  width: 100%;
}
.caixa-foto .icon-play {
  position: absolute;
  display: block;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  cursor: pointer;
  background-color: #1a1a1a;
  font-size: 30px;
  color: #fff;
  padding: 25px 25px 25px 30px;
  border-radius: 60px;
  opacity: 0.9;
}
.caixa-foto .icon-play:hover {
  background-color: #666666;
}
.caixa-foto .local-venda {
  background: #313131;
  color: #fff;
  font-size: 10px;
  padding: 2px 4px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
}
.caixa-foto .botao-comparar {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 9px;
  display: inline-block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 3px 7px;
  background: #eeeeee;
  opacity: 0.5;
}
.caixa-foto .botao-comparar:hover {
  background: #d5d5d5;
  opacity: 0.9;
}

.caixa-de-valor {
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.caixa-de-valor .valor-do-carro .cifrao {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}
.caixa-de-valor .valor-do-carro .valor {
  color: #000;
  font-size: 20px;
  font-weight: 900;
}

.quantidade-de-paginas {
  text-align: right;
  padding-top: 20px;
  padding-bottom: 20px;
}
.quantidade-de-paginas li {
  margin-left: 3px;
  margin-right: 3px;
  display: inline-block;
  background: #f2f2f2;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
}
.quantidade-de-paginas li a {
  color: #9f9f9f;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}
.quantidade-de-paginas li:hover {
  background: #000;
}
.quantidade-de-paginas li:hover a {
  color: #fff;
}
.quantidade-de-paginas li.pagina-atual {
  background: #000;
}
.quantidade-de-paginas li.pagina-atual a {
  color: #fff;
}

.detalhes-marcas {
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}

.titulo-marcas {
  display: block;
  text-transform: uppercase;
  background: #fff;
  margin: 0 auto;
  margin-top: -30px;
  width: 12%;
  font-size: 12px;
  color: #a1a1a1;
}

.marcas {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.marcas .item-marcas {
  margin: 10px;
  padding: 10px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #000;
}
.marcas .item-marcas images {
  display: inline-block;
}
.marcas .item-marcas:hover {
  border: solid 1px #8a8a8a;
}

.box-estoque-seminovas {
  padding-top: 50px;
}
.box-estoque-seminovas .ordenar-por span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 11px;
  color: #1b1b1b;
}
.box-estoque-seminovas .ordenar {
  margin-top: 20px;
  margin-bottom: 20px;
}
.box-estoque-seminovas .ordenar li {
  display: inline-block;
  border-right: solid 1px #aeaeae;
  padding-right: 10px;
  padding-left: 10px;
}
.box-estoque-seminovas .ordenar li a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
  color: #1b1b1b;
}
.box-estoque-seminovas .ordenar li a:hover {
  color: #000;
}
.box-estoque-seminovas .ordenar li:first-child {
  padding-left: 0;
}
.box-estoque-seminovas .ordenar li:last-child {
  border-right: none;
}
.box-estoque-seminovas .mostrar-quantidade {
  margin-top: 15px;
}
.box-estoque-seminovas .mostrar-quantidade span {
  display: inline-block;
  font-size: 11px;
  color: #1b1b1b;
}
.box-estoque-seminovas .mostrar-quantidade select {
  display: inline-block;
  width: auto;
  margin-left: 10px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  height: 30px;
  font-size: 11px;
}

.box-principais {
  text-align: center;
}

#estoque-filtro .filtro {
  background: #f1f1f1;
  display: none;
}
#estoque-filtro .filtro[style*="display: block"] {
  display: flex !important;
}

.btn.btn-outline {
  border: solid 1px #e1e1e1;
  border-radius: 0.375rem;
  font-size: 0.8rem;
  color: #000;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66%;
}

@media screen and (min-width: 320px) {
  .box-principais .botao-default {
    padding-left: 30px;
    padding-right: 30px;
  }
  .box-marcas .marcas-seminovos {
    text-align: left;
  }
  .box-marcas .marcas-seminovos .item-marca-seminovos {
    margin-bottom: 20px;
  }
  .card-carro .thumb-foto {
    text-align: center;
  }
  .card-carro .thumb-foto images {
    width: 100%;
    max-width: inherit;
  }
  .box-principais .botao-default {
    padding-left: 30px;
    padding-right: 30px;
  }
  .titulo-marcas,
  .busca-avancada {
    width: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .box-marcas .marcas-seminovos {
    text-align: center;
  }
  .box-marcas .marcas-seminovos .item-marca-seminovos {
    margin-bottom: 15px;
  }
  .box-marcas .marcas {
    text-align: center;
  }
  .box-marcas .marcas .item-marcas {
    margin-bottom: 15px;
  }
}
.interna-page .form-component label {
  color: #fff;
  font-size: 10px;
}
.interna-page .form-component input, .interna-page .form-component select {
  font-size: 12px;
  color: #fff;
}
.interna-page .form-component input::placeholder, .interna-page .form-component select::placeholder {
  color: #fff;
}
.interna-page .form-component option {
  color: #000;
}
.interna-page .form-component p {
  font-size: 10px;
}

.internal-style .internal-title {
  color: #000;
  text-transform: uppercase;
  font-family: "OpenSansExtraBoldItalic";
}
.internal-style .internal-title h2 {
  font-size: 44px;
  margin: 0;
  line-height: 40px;
}
.internal-style .internal-title h3 {
  font-size: 24px;
  margin: 0;
  line-height: 20px;
}
@media screen and (max-width: 425px) {
  .internal-style .internal-title h2 {
    font-size: 35px;
    line-height: 35px;
  }
}
.internal-style .internal-banner {
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.internal-style .internal-banner img {
  height: 185px;
}
.internal-style .component-title {
  color: #000;
}

.box-interna-carro {
  margin-top: 50px;
}
.box-interna-carro .marca-do-carro,
.box-interna-carro .nome-do-carro {
  display: inline-block;
}
.box-interna-carro .marca-do-carro:hover,
.box-interna-carro .nome-do-carro:hover {
  color: white;
}
.box-interna-carro .marca-do-carro {
  vertical-align: top;
  padding-top: 5px;
  margin-right: 10px;
}
.box-interna-carro .marca-do-carro img {
  display: inline-block;
}
.box-interna-carro .marca-do-carro:hover {
  color: white;
}
.box-interna-carro .nome-do-carro-interna {
  color: #000;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  display: inline-block;
}
.box-interna-carro .nome-do-carro-interna strong {
  font-size: 1.3rem;
  font-weight: 900;
}
.box-interna-carro .fotos-do-carro {
  margin-top: 20px;
  position: relative;
}
.box-interna-carro .fotos-do-carro img {
  width: 100%;
  max-height: 580px;
}
.box-interna-carro .fotos-do-carro .seta-esquerda,
.box-interna-carro .fotos-do-carro .seta-direita,
.box-interna-carro .fotos-do-carro .seta-direita-mobile,
.box-interna-carro .fotos-do-carro .seta-esquerda-mobile {
  z-index: 100;
  color: #fff;
  position: absolute;
  top: 45%;
  display: none;
  width: 50px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 50px;
}
.box-interna-carro .fotos-do-carro:hover .seta-esquerda,
.box-interna-carro .fotos-do-carro:hover .seta-direita,
.box-interna-carro .fotos-do-carro:hover .seta-direita-mobile,
.box-interna-carro .fotos-do-carro:hover .seta-esquerda-mobile {
  display: block;
}
.box-interna-carro .fotos-do-carro .seta-direita,
.box-interna-carro .fotos-do-carro .seta-direita-mobile {
  right: 0;
}
.box-interna-carro .fotos-do-carro .seta-esquerda,
.box-interna-carro .fotos-do-carro .seta-esquerda-mobile {
  left: 0;
}
.box-interna-carro .galeria-thumb {
  background: #e6e6e6;
  padding-top: 10px;
  text-align: center;
}
.box-interna-carro .galeria-thumb .item-thumbs {
  max-width: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.box-interna-carro .galeria-thumb .item-thumbs img {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  max-width: 95px;
}
.box-interna-carro .gallery-medias {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}
.box-interna-carro .gallery-medias .main-element {
  grid-row: span 2;
}
.box-interna-carro .gallery-medias img,
.box-interna-carro .gallery-medias iframe {
  width: 100%;
}
.box-interna-carro .gallery-medias .overlay-last-image {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1f1f1;
  top: 0;
  width: 100%;
  height: 100%;
}

.quadro-informacoes-do-carro .item-informacao {
  height: 50px;
  line-height: 50px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 300;
}
.quadro-informacoes-do-carro .item-informacao span {
  color: #000;
}
.quadro-informacoes-do-carro .item-informacao img {
  display: inline-block;
  margin-right: 10px;
}
.quadro-informacoes-do-carro .item-informacao.cinza {
  background: #f7f7f7;
}

.caixa-caracteristica {
  margin-bottom: 50px;
}
.caixa-caracteristica .caixa-titulo {
  display: inline-block;
  width: 100%;
  padding-left: 15px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
  background: #313131;
  line-height: 60px;
}
.caixa-caracteristica .tag-principal {
  display: inline-block;
  width: 100%;
  color: #000;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.caixa-caracteristica .tag {
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  color: #000;
  font-size: 16px;
  margin-bottom: 15px;
}
.caixa-caracteristica .info-caracteristica {
  display: inline-block;
  width: 100%;
  color: #000;
  font-weight: 400;
  padding-left: 20px;
  font-size: 12px;
  margin-bottom: 30px;
  padding-right: 60px;
}

.compartilhar {
  border-top: solid 1px #aaaaaa;
  padding-top: 10px;
  width: 95%;
  margin: 0 auto;
  margin-top: 30px;
}
.compartilhar span {
  font-size: 12px;
  color: #000;
}

.compartilhar-btn {
  text-align: center;
  width: 100%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-top: 20px;
}
.compartilhar-btn:hover {
  color: #fff;
}
.compartilhar-btn.whatsapp {
  background: #65bc54;
}
.compartilhar-btn.facebook {
  background: #3c6ecb;
}
.compartilhar-btn.youtube {
  background: #dc3333;
}
.compartilhar-btn.twitter {
  background: #52d4f9;
}
.compartilhar-btn.instagram {
  background: #e87e21;
}

.lista-de-caracteristicas {
  display: flex;
}
.lista-de-caracteristicas li {
  display: flex;
  border: solid 1px #e1e1e1;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}
.lista-de-caracteristicas li span {
  font-size: 12px;
  color: #000;
}
.lista-de-caracteristicas li:first-child {
  margin-left: 0;
}

.opcional-check {
  width: 100%;
  display: inline-block;
  color: #000;
  border: solid 1px #e1e1e1;
  padding: 10px;
}
.opcional-check i {
  margin-right: 5px;
  font-size: 18px;
  color: #27ae60;
}

.valor-carro {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.valor-carro .cifrao {
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
}
.valor-carro .valor {
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
}

.de {
  color: #000;
  font-size: 14px;
}
.de .valor-antes {
  text-decoration: line-through;
}

.car-right-box {
  position: absolute;
}

.lead-preco {
  border-top: solid 1px #fff;
  background: #000;
  text-align: center;
}

.tag-lead-interna {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  padding: 0px 10px;
}

.enviar-lead-interna {
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
  background: #fff;
  color: #000;
  width: 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: none;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}
.enviar-lead-interna i {
  font-size: 12px;
}
.enviar-lead-interna:hover {
  background-color: black;
  color: #fff;
  border: solid 1px #777;
}

.tag-lead-menor {
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  display: inline-block;
}

.box-radios .radio {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.box-radios .radio label {
  color: #615f6c;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}

.btn-simulacao {
  background-color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  width: 95%;
}
.btn-simulacao:hover {
  color: #fff;
  background-color: black;
}

.lead-interna-carro input {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  color: #1b1b1b;
}
.lead-interna-carro textarea {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-bottom: 12px;
  height: 100px;
  color: #1b1b1b;
}

.box-inputs {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 992px) {
  .car-right-box {
    position: absolute;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 320px) {
  .box-interna-carro {
    margin-top: 0px;
  }
  .box-interna-carro .fotos-do-carro {
    margin-top: 0;
  }
  .car-right-box {
    position: relative;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .box-interna-carro .fotos-do-carro {
    margin-top: 20px;
  }
  .box-interna-carro {
    margin-top: 20px;
  }
}
/*===========================================
	OFERTAS
============================================*/
.card-component {
  min-height: 400px;
  text-align: center;
}
.card-component .card-ctas .col-sm-6, .card-component .card-ctas .col-md-6,
.card-component .card-ctas .col-md-12, .card-component .card-ctas .col-sm-12 {
  padding-left: 5px;
  padding-right: 5px;
}
.card-component .card-ctas .button.button-smaller {
  font-size: 0.7em !important;
  padding: 0.25em 0.25em !important;
}
.card-component .visible {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
}
.card-component ul {
  margin-top: 0;
  height: 160px;
  text-align: left;
  list-style: none;
  animation: 6s linear 0s normal none infinite change;
}
.card-component ul li {
  line-height: 40px;
  margin: 0;
  height: 40px;
  margin-bottom: 0px;
  text-align: center;
}
@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%, 12%, 100% {
    transform: translateY(0);
  }
  17%, 29% {
    transform: translateY(-25%);
  }
  34%, 46% {
    transform: translateY(-50%);
  }
  51%, 63% {
    transform: translateY(-75%);
  }
  68%, 80% {
    transform: translateY(-50%);
  }
  85%, 97% {
    transform: translateY(-25%);
  }
}
.card-component .card-content-image {
  height: 170px;
  display: flex;
  align-items: flex-end;
}
.card-component .card-content-image .card-image {
  width: 100%;
  height: auto;
}
.card-component .button-green {
  font-size: 0.77em;
  padding: 0.6em 1.3em;
}
.card-component .card-title {
  font-family: "OpenSansExtraBoldItalic";
  font-size: 24px;
  text-transform: uppercase;
  color: #3E3F40;
  min-height: 56px;
}
.card-component .card-price {
  font-size: 12px;
  color: #3E3F40;
}
.card-component .de {
  margin: 0;
  font-size: 0.8rem;
  text-decoration: line-through;
}
.card-component .prepreco {
  margin: 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.card-component .por {
  font-size: 1.4rem;
  font-weight: initial;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.card-component .contagem-regressiva {
  color: #cf403e;
}
.card-component .containuer-preco-consorcio {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
  border-top: solid 1px #04a3e1;
  border-bottom: solid 1px #04a3e1;
  padding: 9px 0px 1px 0px;
}
.card-component .info .condicoes {
  margin: 0;
  background: #04a3e1;
  color: #fff;
  font-size: 0.7rem;
  padding: 5px 0px;
  font-weight: 100;
  text-transform: uppercase;
}
.card-component .info .pospreco {
  margin: 0;
  padding: 2px 0px;
  text-transform: uppercase;
  border-top: solid 1px #04a3e1;
  border-bottom: solid 1px #04a3e1;
  font-weight: 500;
  margin-top: 10px;
  color: #04a3e1;
  margin-bottom: 10px;
}
.card-component .info .extras {
  text-align: left;
  font-weight: 300;
  margin-top: 0px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #333;
}
.card-component .card-button {
  font-size: 12px;
  color: #3E3F40;
}

@media screen and (max-width: 476px) {
  .card-component .card-content-image {
    margin-top: 70px;
  }
}
/*===========================================
	RESPONSIVO
============================================*/
@media screen and (min-width: 320px) {
  /* 320px */
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda,
  .seta-outro-esquerda,
  .seta-outro-direita {
    display: none !important;
  }
  .filtro li {
    border: none;
  }
  .botao-pos-venda {
    margin-bottom: 30px;
  }
  .botao-pos-venda i {
    display: none;
  }
  .botao-pos-venda img {
    margin-right: 5px;
  }
  .botao-ver-mais-carros i {
    display: none;
  }
  .menu-footer {
    margin-bottom: 30px;
  }
  .atendimento {
    display: block;
  }
  .ass {
    text-align: center;
    margin-top: 30px;
  }
  .horarios-e-mapa {
    text-align: center;
    border-left: none;
  }
  .horarios-e-mapa .mapa {
    margin-bottom: 20px;
  }
  .logo-da-empresa,
  .horario,
  .nome-da-loja {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }
  footer {
    text-align: center;
  }
  .clique-menu {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }
  .clique-menu i {
    margin-left: 5px;
  }
  .box-menu-mobile {
    background: #000;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 9000;
    left: 0;
    top: 0;
    border-top: 8px solid #fff;
    display: none;
  }
  .clique-fechar {
    text-transform: uppercase;
    font-weight: 900;
    text-align: right;
    width: 100%;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 20px;
  }
  .menu-mobile li {
    text-align: center;
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
  }
  .menu-mobile li a {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 26px;
    color: #fff;
  }
  .menu-mobile li a:hover {
    text-decoration: underline;
  }
  .menu-mobile li .sub-menu {
    background: #252525;
    width: 100%;
    z-index: 9000;
    display: none;
  }
  .menu-mobile li .sub-menu li {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .menu-mobile li .sub-menu li a {
    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: solid 1px #fff;
  }
  .menu-mobile li .sub-menu li:hover {
    background: #000;
  }
  .menu-mobile li .sub-menu li:hover a {
    border-color: #000;
    text-decoration: none;
  }
  .menu-mobile li .sub-menu li:last-child a {
    border-bottom: none;
  }
  .box-contato .sobre-pagina {
    padding-left: 30px;
    padding-right: 15px;
    margin-top: -200px;
  }
  .sobre-pagina {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -200px;
  }
  .menor-ativo {
    margin-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .slider-principal .item-slider {
    height: auto;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .slider-principal.--mobile {
    display: none;
  }
  .slider-principal.--desktop {
    display: block;
  }
  .slider-principal .item-slider {
    height: 470px;
    padding-top: 0;
  }
  .slider-principal .item-slider img {
    width: 100%;
  }
  .filtro li {
    border-right: solid 1px #7e7e7e;
  }
  .botao-pos-venda {
    margin-bottom: 30px;
  }
  .botao-pos-venda i {
    display: block;
  }
  .botao-pos-venda img {
    margin-right: 25px;
  }
  .botao-ver-mais-carros i {
    display: inline-block;
  }
  .menu-footer {
    margin-bottom: 0px;
  }
  footer {
    text-align: left;
  }
  .ass {
    text-align: right;
    margin-top: 0px;
  }
  .horarios-e-mapa {
    text-align: right;
    border-left: solid 1px #e3e3e3;
  }
  .horarios-e-mapa .mapa {
    margin-bottom: 0px;
    text-align: left;
  }
  .logo-da-empresa {
    margin-right: 30px;
    width: auto;
    text-align: left;
  }
  .horario,
  .nome-da-loja {
    width: auto;
    text-align: left;
  }
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda {
    display: none !important;
  }
  .menor-ativo {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .seta-direita,
  .seta-esquerda,
  .seta-slider-de-carros-direita,
  .seta-slider-de-carros-esquerda,
  .seta-slider-venda-direta-esquerda,
  .seta-slider-venda-direta-direita,
  .seta-slider-simule-direita,
  .seta-slider-simule-esquerda,
  .seta-peca-direita,
  .seta-peca-esquerda,
  .seta-outro-esquerda,
  .seta-outro-direita {
    display: inline-block !important;
  }
  .seta-slider-de-carros-direita {
    right: 1%;
  }
  .seta-slider-de-carros-esquerda {
    left: 1%;
  }
  .box-contato .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: -80px;
  }
  .sobre-pagina {
    padding-left: 95px;
    padding-right: 95px;
    margin-top: -80px;
  }
}
@media screen and (min-width: 1400px) {
  .seta-slider-de-carros-direita {
    right: -5%;
  }
  .seta-slider-de-carros-esquerda {
    left: -5%;
  }
}
@media screen and (max-width: 540px) {
  .horario,
  .mapa {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .botao-pos-venda img {
    display: none;
  }
}