.interna-page{

    .form-component{

        label{
            color: $branco;
            font-size: 10px;
        }

        input, select{
            font-size: 12px;
            color: $branco;

            &::placeholder{
                color: $branco;
            }
        }
        option{
            color: $preto;
        }
        p{
            font-size: 10px;
        }
    }


}
