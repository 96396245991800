.consorcio-table{
    td, th{
        border: 1px solid #fff;
        padding: 5px;
    }
}

.car-icon {
    display: inline-block;
    width: 20px;
    height: 21px;
    vertical-align: middle;
    &.combustivel {
        background: url(../images/icones/icons_hover.png);
        background-position: -46px 0px;
        padding-left: 23px;
    }
    &.portas {
        background: transparent url("../images/icones/07.png") scroll no-repeat left center;
    }
    &.cor {
        background: transparent url("../images/icones/06.png") scroll no-repeat left center;
    }
    &.cambio {
        background: transparent url("../images/icones/05.png") scroll no-repeat left center;
    }
    &.ano {
        background: url(../images/icones/icons_hover.png);
        background-position: 0 0px;
        padding-left: 23px;
    }
    &.quilometragem {
        background: url(../images/icones/icons_hover.png);
        background-position: -24px 0px;
        padding-left: 23px;
    }
    &.motor {
        background: transparent url("../images/icones/02.png") scroll no-repeat left center;
    }
    &.car {
        background: transparent url("../images/icones/01.png") scroll no-repeat left center;
    }
}

.card-carro {
    background-color: #fff;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    .car-icon {
        color: #8D8D8D;
        &.ano {
            background: url(../images/icones/icons_hover.png);
            background-position: 0 0px;
        }
        &.quilometragem {
            background: url(../images/icones/icons_hover.png);
            background-position: -24px 0px;
        }
        &.combustivel {
            background: url(../images/icones/icons_hover.png);
            background-position: -46px 0px;
        }
    }
    &:hover {
        .valor {
            color: $corMain !important;
        }
        .cifrao {
            color: $corMain !important;
        }
        .nome-do-carro {
            color: $corMain !important;
        }
        .versao {
            color: $corMain !important;
        }

    }
    .tag-oferta {
        color: white;

        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 140px;
        height: 0;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        border-top: 20px solid $corMain;
        border-right: 15px solid transparent;
        border-left: 15px solid $corMain;

        span {
            position: absolute;
            width: 115px;
            top: -22px;
            left: -10px;
        }
    }
    .botao-default {
        display: block;
        background: unset;
        text-transform: capitalize;
        color: $corMain;
        text-align: center;
        border-radius: 5px;
        border: solid 1px $corMain;
        font-size: 12px;
        font-weight: 700;
        width: fit-content;
        padding: 10px 10px !important;
        margin: 0;
        i {
            font-size: 12px;
        }

        &.ligamos-para-voce {
            background: #21a45c;
            &:hover {
                background: #26d775;
            }
        }
    }

    .container-nome {
        .marca-do-carro {
            display: inline-block;
            width: 100%;
        }

        .nome-do-carro {
            color: $corMain;
            width: 100%;
            display: flex;
            align-items: center;

            images {
                width: 26px;
                margin-right: 10px;
            }
        }

        .versao,
        .tipo-do-carro {
            color: $corMain;
            height: 35px;
            text-transform: uppercase;
            margin: 0;
            display: flex;
            font: {
                size: 11px;
            }
        }

        ul.caixa-specs {
            display: flex;
            justify-content: space-around;
            li {
                color: #999;
                font-size: 10px;
                padding: 5px 2px;

                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
                span.car-icon {
                    width: auto;
                    line-height: 20px;
                }
            }
        }
    }
    .caixa-whatsapp {
        text-align: center;
        background-color: #5cb85c;
        color: white;
        padding: 10px;
        margin-top: 10px;
    }
}

.caixa-foto {
    position: relative;
    margin-bottom: 15px;
    display: block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    images {
        width: 100%;
    }

    .icon-play {
        position: absolute;
        display: block;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        cursor: pointer;
        background-color: lighten($corMain, 10%);
        font-size: 30px;
        color: $branco;
        padding: 25px 25px 25px 30px;
        border-radius: 60px;
        opacity: 0.9;
        &:hover {
            background-color: lighten($corMain, 40%);
        }
    }

    .local-venda {
        background: #313131;
        color: $branco;
        font-size: 10px;
        padding: 2px 4px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.5;
    }

    .botao-comparar {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: 700;
        color: $branco;
        text-transform: uppercase;
        font-size: 9px;
        display: inline-block;
        @include radius(5px);
        @include transition(0.3s);
        padding: 3px 7px;
        background: $secondary-color;
        opacity: 0.5;
        &:hover {
            background: darken($secondary-color, 10%);
            opacity: 0.9;
        }
    }
}

.caixa-de-valor {
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .valor-do-carro {
        .cifrao {
            color: $corMain;
            font-size: 14px;
            font-weight: normal;
        }
        .valor {
            color: $corMain;
            font-size: 20px;
            font-weight: 900;
        }
    }
}

.quantidade-de-paginas {
    text-align: right;
    padding-top: 20px;
    padding-bottom: 20px;
    li {
        margin-left: 3px;
        margin-right: 3px;
        display: inline-block;
        background: #f2f2f2;
        @include radius(2px);
        @include transition(0.3s);
        text-align: center;

        a {
            color: #9f9f9f;
            @include transition(0.3s);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
        }
        &:hover {
            background: $corMain;
            a {
                color: $branco;
            }
        }

        &.pagina-atual {
            background: $corMain;
            a {
                color: $branco;
            }
        }
    }
}


.detalhes-marcas {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.titulo-marcas {
    display: block;
    text-transform: uppercase;
    background: #fff;
    margin: 0 auto;
    margin-top: -30px;
    width: 12%;
    font: {
        size: 12px;
    }
    color: #a1a1a1;
}

.marcas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item-marcas {
        margin: 10px;
        padding: 10px;
        border: solid 1px #e5e5e5;
        border-radius: 5px;
        font-size: 0.8rem;
        color: $preto;
        images {
            display: inline-block;
        }
        &:hover {
            border: solid 1px #8a8a8a;
        }
    }
}

.box-estoque-seminovas {
    padding-top: 50px;

    .ordenar-por {
        span {
            display: inline-block;
            width: 100%;
            text-align: left;
            font-size: 11px;
            color: #1b1b1b;
        }
    }

    .ordenar {
        margin-top: 20px;
        margin-bottom: 20px;
        li {
            display: inline-block;
            border-right: solid 1px #aeaeae;
            padding-right: 10px;
            padding-left: 10px;
            a {
                @include transition(0.3s);
                font-size: 14px;
                color: #1b1b1b;
                &:hover {
                    color: $corMain;
                }
            }
        }
        li:first-child {
            padding-left: 0;
        }
        li:last-child {
            border-right: none;
        }
    }

    .mostrar-quantidade {
        margin-top: 15px;
        span {
            display: inline-block;
            font-size: 11px;
            color: #1b1b1b;
        }

        select {
            display: inline-block;
            width: auto;
            margin-left: 10px;
            @include radius(0);
            height: 30px;
            font-size: 11px;
        }
    }
}

.box-principais {
    text-align: center;

}

#estoque-filtro{

  .filtro {
      background: #f1f1f1;
      display: none;
  }

  .filtro[style*="display: block"] {
      display: flex !important;
  }
}

.btn{
    &.btn-outline {
        border: solid 1px #e1e1e1;
        border-radius: 0.375rem;
        font-size: 0.8rem;
        color: $corMain;
    }
}

.ratio-3x2{
  --bs-aspect-ratio: 66%;
}
@media screen and (min-width: 320px) {

    .box-principais{
        .botao-default{
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .box-marcas{
        .marcas-seminovos{
            text-align: left;
            .item-marca-seminovos{
                margin-bottom: 20px;
            }
        }

        .marcas{
            .item-marcas{
                //margin-bottom: 20px;
            }
        }
    }

    .card-carro{
        .thumb-foto{
            text-align: center;
            images{
                width: 100%;
                max-width: inherit;
            }
        }
    }

    .box-principais{
        .botao-default{
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .titulo-marcas,
    .busca-avancada{ width: 60%; }
}

@media screen and (min-width: 1200px) {
    .box-marcas{
        .marcas-seminovos{
            text-align: center;
            .item-marca-seminovos{
                margin-bottom: 15px;
            }
        }

        .marcas{
            text-align: center;
            .item-marcas{
                margin-bottom: 15px;
            }
        }
    }
}
